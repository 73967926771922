<template>

  <div>

    <div class="d-flex justify-content-between navbar-dark w-100" style="padding-top: 88px;background-color: var(--primary-dark);overflow-y: scroll;scrollbar-width: none;-ms-overflow-style: none;">

      <router-link to="/" class="nav-item">
        <div class="nav-icon">
          <img src="/assets/images/general.png" height="35">
          <div class="nav-text white-txt bold" style="text-align: center">Sports Book</div>
        </div>
      </router-link>

      <router-link to="/jetsafi" class="nav-item">
        <div class="nav-icon">
          <img src="/assets/images/casino.png" height="35">
          <div class="nav-text white-txt bold" style="text-align: center">JetSafi</div>
        </div>
      </router-link>

      <router-link to="/casino" class="nav-item">
        <div class="nav-icon">
          <img src="/assets/images/casino.png" height="35">
          <div class="nav-text white-txt bold" style="text-align: center">Casino</div>
        </div>
      </router-link>

      <router-link to="/xgames" class="nav-item">
        <div class="nav-icon">
          <img src="/assets/images/casino.png" height="35">
          <div class="nav-text white-txt bold" style="text-align: center">X Games</div>
        </div>
      </router-link>

      <router-link to="/endorphina" class="nav-item">
        <div class="nav-icon">
          <img src="/assets/images/casino.png" height="35">
          <div class="nav-text white-txt bold" style="text-align: center">Spin & Win</div>
        </div>
      </router-link>

      <router-link to="/rocketman" class="nav-item">
        <div class="nav-icon">
          <img src="/assets/images/rocketman.svg" height="35">
          <div class="nav-text white-txt bold" style="text-align: center">Rocket Man</div>
        </div>
      </router-link>

      <router-link to="/virtuals" class="nav-item">
        <div class="nav-icon">
          <img src="/assets/images/virtuals.jpeg" height="35">
          <div class="nav-text white-txt bold" style="text-align: center">Virtuals</div>
        </div>
      </router-link>

      <router-link to="/casino/live" class="nav-item">
        <div class="nav-icon">
          <img src="/assets/images/live-casino.jpeg" height="35">
          <div class="nav-text white-txt bold" style="text-align: center">Live Casino</div>
        </div>
      </router-link>

    </div>

  </div>

</template>

<style scoped>
  .icon-new.red {
    color: #fff;
    background-color: red;
  }
  .icon-new {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    font-size: 8px;
    background: #fff30a;
    background-color: rgb(255, 243, 10);
    padding: 2px 4px;
    color: red;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: 700;
    z-index: 702;
  }
</style>
<script>

export default {
  name: "Menu",
  methods: {

    getKey: function(index){

      return Math.random().toString(11).replace('0.', 'sport-'+index + '-');

    },
    setSport: function (sportID) {

        this.$store.dispatch("setSportID",sportID);

        if(this.$store.state.current_page === 'home')
          return

        this.$router.push({name: 'home'});
    },
    getMenuTextClass: function (sportID){

      return this.current_page === "esport" || this.current_page === "freebet" ? "" :  parseInt(this.$store.state.sport_id) === parseInt(sportID) ? "menu-active" : "";

    },
    isActiveMenu: function (name){

      return this.current_page === name ? "menu-active" : "";

    },
    resetGames: function(){

      this.$store.dispatch("resetAllGames");

      //if(this.current_page !== "home") {

        this.goHome();

      //}

    }
  },
  computed: {
    iconSize: function (){

      return 20;

    },
    current_page: function () {

      return this.$store.state.current_page
    },
    show_freebet: function (){

      var p = this.getProfile();
      if(!p) {

        return true;

      }

      return parseInt(p.b4) > 0;

    },
    sports: function() {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s,function(k,v){

        if(v.sport_id > 1 ) {

          t.push(v);
        }

      })

      return t;
    },
  },
}
</script>